import { mapState } from "vuex";
import axios from "axios";

export default {
    name: "mixinFilial",
    data: () => ({
        filiais: {},
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "usuario"]),
    },
    methods: {
        listarEmpresas() {
            return axios
                .post(`${this.backendUrl}filial/listar`, {
                    limit: 9999,
                    offset: 0,
                    busca: null,
                })
                .then((res) => {
                    this.filiais = res.data;
                    this.filiais.lista = this.filiais.lista.filter((v) => {
                        return (
                            v.idfilial != 22 &&
                            v.idfilial != 32 &&
                            v.idfilial != 34
                        );
                    });
                });
        },
    },
    created() {
        this.listarEmpresas();
    },
}