<template>
	<v-row>
		<v-dialog v-model="dialogMensagem.status">
			<v-card>
				<v-card-title class="justify-center">Erro de processo</v-card-title>
				<div class="text-center py-3">
					<v-icon size="150" color="error">mdi-alert-outline</v-icon>
				</div>
				<v-card-text class="text-h6 text-center">
					{{ dialogMensagem.mensagem }}
				</v-card-text>
				<v-card-actions class="justify-center pb-5">
					<v-btn color="primary" elevation="0" class="px-10" width="50%" @click="dialogMensagem.status = false">OK</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogRetorno" width="700px" persistent>
			<v-card>
				<v-card-title>Importação realizada com sucesso<v-spacer/><span style="font-size: 0.8em">Nº planilha: {{ retorno.lista }}</span></v-card-title>
				<v-card-subtitle>Produtos ativados</v-card-subtitle>
				<v-card-text>
					<v-simple-table>
						<thead>
						<tr class="blue-grey lighten-4">
							<th>Código produto</th>
							<th>Descrição produto</th>
							<th></th>
						</tr>
						</thead>
						<tbody v-if="retorno.ativo">
						<tr v-for="(col, i) in retorno.ativo" :key="i">
							<td>{{ col.idsubproduto }}</td>
							<td>{{ col.produto }}</td>
							<td class="text-center"><v-chip small class="success">Ativado</v-chip></td>
						</tr>
						</tbody>
					</v-simple-table>
					<div class="text-center grey lighten-4" v-if="!retorno.ativo">
						<span class="caption"><strong>Nenhum produto ativado na importação do arquivo.</strong></span>
					</div>
				</v-card-text>
				<v-card-actions class="justify-center">
					<v-btn color="primary" elevation="0" class="px-10" width="100%" @click="dialogRetorno = false, dialogCusto = true">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog >
		<v-dialog v-model="dialogCusto" width="700px" persistent scrollable>
			<v-card>
				<v-card-title>Produtos com custos zerados<v-spacer/><span style="font-size: 0.8em">Nº planilha: {{ retorno.lista }}</span></v-card-title>
				<v-card-text>
					<v-simple-table>
						<thead>
						<tr class="blue-grey lighten-4">
							<th>Código produto</th>
							<th>Descrição produto</th>
						</tr>
						</thead>
						<tbody v-if="retorno.custo">
						<tr v-for="(col, i) in retorno.custo" :key="i">
							<td>{{ col.idsubproduto }}</td>
							<td>{{ col.produto }}</td>
						</tr>
						</tbody>
					</v-simple-table>
					<div class="text-center grey lighten-4" v-if="!retorno.custo">
						<span class="caption"><strong>Nenhum produto com custo zerado na importação do arquivo.</strong></span>
					</div>
				</v-card-text>
				<v-card-actions class="justify-center">
					<v-btn color="primary" elevation="0" class="px-10" width="100%" @click="dialogCusto = false">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogConfirmacao.status" persistent v-if="idempresa > 0" width="600px">
			<v-card class="pa-5">
				<v-card-title class="text-h4">
					<span class="mx-auto">Verificação</span>
				</v-card-title>
				<div class="text-center py-3">
					<v-icon size="150" color="error">mdi-alert-octagon-outline</v-icon>
				</div>
				<v-card-text class="text-h6 text-center">
					Confirma a importação do arquivo de balanço com a empresa e arquivo selecionado?
				</v-card-text>
				<v-card-text style="font-size: 1.1em">
					<span class="pb-2"><strong>Empresa:</strong> {{idempresa}} - {{filiais.lista.filter(v => (v.idfilial == idempresa))[0].filial}}</span><br/>
					<span class="mt-2"><strong>Nome do arquivo:</strong> {{arquivo.name}}</span>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" elevation="0" class="px-10" width="50%" @click="dialogConfirmacao.status = false">Não</v-btn>
					<v-btn color="error" elevation="0" class="px-10" width="50%" @click="dialogConfirmacao.status = false, importar()">Sim</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="12">
			<v-card>
				<v-row no-gutters>
					<v-col>
						<v-card-text class="pr-0">
							<v-select
								:items="filiaisFiltro"
								item-text="filial"
								item-value="idfilial"
								label="Filial"
								outlined
								dense
								:disabled="carregando"
								v-model="idempresa"
								hide-details
							></v-select>
						</v-card-text>
					</v-col>
					<v-col cols="4">
						<v-card-text class="pr-0">
							<v-file-input
								:disabled="idempresa <= 0 || carregando"
								v-model="arquivo"
								accept=".txt"
								@change="adicionarArquivo()"
								placeholder="anexe aqui o arquivo final"
								hide-details
								small
								outlined
								dense
								prepend-icon
								prepend-inner-icon="mdi-paperclip"
							></v-file-input>
						</v-card-text>
					</v-col>
					<v-col>
						<v-card-title>
							<v-btn :loading="carregando" :disabled="idempresa <= 0 || arquivo <=0 || carregando" width="100%" height="40px" @click="dialogConfirmacao.status = true" class="primary"><v-icon size="25" class="mr-1">mdi-upload</v-icon>Enviar arquivo</v-btn>
						</v-card-title>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
		<v-col>
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-text class="text-center pt-8">
					<strong
						class="text-h6"
					>Selecione a filial e arquivo desejado e depois clique em "ENVIAR ARQUIVO" para efetuar a importação.</strong>
					<br />
					<br />
					<v-icon
						color="primary lighten-5"
						class="my-2"
						size="100"
					>mdi-file-upload</v-icon>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";
import axios from "axios";

export default {
	mixins: [mixinFilial],
	name: "BalancoImportador",
	data: () => ({
		dialogConfirmacao: { status: false } ,
		carregando: false,
		idempresa: 0,
		dados: [],
		page: 1,
		pageCount: 0,
		arquivo: {},
		arquivos:[],
		dialogRetorno: false,
		retorno: {ativo:[]},
		dialogMensagem: { status:false, mensagem: 'Balanço não aberto na filial selecionada!'},
		dialogCusto: false,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 33
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		listar() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}balanco/arquivo/listar`, {
					idempresa: this.idempresa || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		importar() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}balanco/importar`, {
					idempresa: this.idempresa,
					arquivo: this.arquivos[0].arquivo,
				})
				.then((res) => {
					this.dialogRetorno = true;
					this.retorno = res.data;
					if (this.retorno.ativo) {
						this.retorno.ativo = []
					}
					this.arquivo = {};
					this.arquivos = [];
					this.carregando = false;
				})
				.catch(() => {
					this.arquivo = {};
					this.arquivos = [];
					this.dialogMensagem.status = true;
					this.dialogMensagem.mensagem = 'Balanço não aberto na filial selecionada!';
					this.carregando = false;
				});
		},
		adicionarArquivo() {
			if (this.arquivo && this.arquivo.name != undefined) {
				this.carregando = true
				let obj = {};
				let reader = new FileReader();
				reader.readAsDataURL(this.arquivo);
				let that = this
				reader.onload = function () {
					obj.arquivo = reader.result.split("base64,")[1];
					that.carregando = false
				};
				this.arquivos.push(obj);
			} else {
				this.arquivo = {}
				this.arquivos = []
			}
		},
	},
};
</script>

<style>

</style>